/* INTRO TEXT */
.Hero {
  padding-top: 17%;
  height: 90vh;
}

.Hero__intro {
  font-size: 6.2rem;
  margin-bottom: 5rem;
}
.Hero__intro > span {
  font-size: 5.6rem;
  color: var(--hover-color);
}

/* ARROW */
.arrow-container {
  text-align: center;
}
.arrow-down {
  padding: 0;
  font-size: 7rem;
  animation: pulse infinite linear 2.5s;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    font-size: 7rem;
  }
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  100% {
    font-size: 7rem;
  }
}

@media (min-width: 20em) and (max-width: 40em) {
  /* MEDIA HERO */
  .Hero {
    padding-top: 20%;
    height: auto;
  }

  .Hero__intro,
  .Hero__intro > .type-it {
    font-size: 4.2rem;
  }
}
