/* CONTACT */
.Contact {
  height: 80vh;
  align-items: center;
  justify-content: space-between;
}
.Contact__title {
  font-size: 7.2rem;
}
.Contact__button {
  background-color: var(--hover-color);
  font-size: 2.5rem;
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
  text-align: center;
  padding: 5rem;
  text-transform: uppercase;
  color: #eee;
  transition: 0.3s ease-in-out;
}
.Contact__button:hover {
  color: #eee;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

/* MEDIA CONTACT */
@media (min-width: 20em) and (max-width: 30em) {
  .Contact {
    flex-direction: column-reverse;
    height: auto;
    padding-top: 0;
    margin-bottom: 2rem;
  }
  .Contact > h1 {
    font-size: 4.2rem;
    margin-bottom: 3rem;
  }
}
