/* NAV */
.theme--button {
  color: var(--text-primary);
  background-color: transparent;
  /* border: 1px var(--text-primary) solid; */
  border: none;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 0.5rem;
  height: fit-content;
  padding: 0.6rem;
  font-family: "Lato", sans-serif;
  margin: 1rem;
  font-size: 2rem;
}

.Navbar {
  justify-content: space-between;
  border-bottom: #333 solid 1px;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 100%;
  top: 0;
  flex-wrap: wrap;
  /* background-color: var(--background); */
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  height: auto;
  background: var(--background-transparent);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* overflow: hidden; */
}

.Navbar__logo {
  margin: 0;
  font-size: 1.8rem;
  align-items: center;
}

.Navbar__logo > img {
  height: 2.6rem;
  margin-left: 1rem;
  margin-right: 1.2rem;
}
.Navbar__logo > img:hover {
  cursor: pointer;
}
.Navbar__logo > h1 {
  font-size: 2rem;
}
.Navbar__list {
  margin: 0;
  list-style: none;
  align-items: center;
}

.Navbar__list li {
  font-size: 1.6rem;
  margin-left: 4rem;
}

/* sidebar Nav */

.hamburger {
  position: fixed;
  z-index: 200;
  top: 0;
  cursor: pointer;
  padding: 0.6rem;
  margin-top: 1rem;
  width: 4rem;
  height: 4rem;
  color: var(--text-primary);
  background: var(--background-transparent);
}
.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: rgba(250, 128, 114, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 20rem;
  z-index: 9;
  flex-direction: column;
  border-right: 1px solid var(--background-transparent);
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 1rem 4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 0;
}

.menuNav li:first-child {
  margin-top: 7rem;
}

/* ************************************************************************ */

/* MEDIA QUERIES */
@media screen and (max-width: 640px) {
  .Navbar {
    margin-top: 0;
    flex-direction: column;
  }
  .Navbar__logo {
    flex-direction: column;
    margin-left: 0;
    text-align: center;
  }
  .Navbar__logo > img {
    padding: 1rem;
    height: 5rem;
  }
  .Navbar__logo > h1 {
    margin-left: 0;
    padding-right: 5px;
    padding-bottom: 1rem;
  }
  .Navbar ul {
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }
  .Navbar li:nth-child(1) {
    margin-left: 0;
  }
  .Navbar li {
    margin-left: 0;
    padding: 2rem;
  }
  .Navbar > .theme--button {
    position: fixed;
    right: 0;
    top: 0;
  }
  .Navbar > .hamburger__btn {
    position: -webkit-sticky;
    position: fixed;
    left: 0px;
    color: var(--text-primary);
    z-index: 200;
  }
}
