/* FOOTER */
.Footer {
  align-items: center;
  justify-content: space-between;
  border-top: #333 solid 1px;
  padding: 20px;
}
.Footer > h1 {
  font-weight: 500;
  word-spacing: 2.2px;
}

.Footer__social > .icon {
  margin-left: 3rem;
  font-size: 2rem;
}
.Footer__social > .icon:hover {
  color: var(--hover-color);
  cursor: pointer;
}

/* MEDIA FOOTER */
@media (min-width: 20em) and (max-width: 40em) {
  .Footer {
    flex-direction: column-reverse;
  }
  .Footer > h1 {
    margin-top: 1rem;
    text-align: center;
  }

  .Footer__social > .icon {
    display: flex;
    justify-content: center;

    text-align: center;
  }
  .Footer__social > :nth-child(1) {
    margin-left: 0;
  }
}
