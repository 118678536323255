/* LIGHT/DARK MODE */

.App {
  color: var(--text-primary);
  background-color: var(--background);
  padding-left: 2rem;
  padding-right: 2rem;
  transition: all 0.5s;
  width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
  /* border: solid red 2px; */
}

/* LINKS */
a {
  text-decoration: none;
  color: var(--text-primary);
}

a:hover {
  color: var(--hover-color);
  cursor: pointer;
}

/* UTILITIES */

.container {
  display: flex;
  margin: 0 auto;
  padding: 2rem;
  width: 75%;
}

.container > * {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

li {
  text-decoration: none;
}

.title {
  font-size: 3.2rem;
  text-transform: uppercase;
  word-spacing: 0.7rem;
}
.paragraph {
  font-size: 1.6rem;
}

/* MEDIA QUERIES */

/* Add responsiveness - on screens less than 500px, make the navigation links appear on top of each other, instead of next to each other */
/* @media screen and (max-width: 500px) {
  .Navbar a {
    float: none;
    display: block;
    width: 100%;
    text-align: left; /* If you want the text to be left-aligned on small screens */
/* } */
/* } */
