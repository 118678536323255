:root {
  font-size: 62.5%;
  --background: #eee;
  --background-transparent: rgba(238, 238, 238, 0.7);
  --text-primary: #333;
  --text-secondary: rgb(182, 73, 61);
  --accent: salmon;
  --hover-color: #fa8072;
}
[data-theme="dark"] {
  --background: #222;
  --background-transparent: rgba(34, 34, 34, 0.7);
  --text-primary: #eee;
  --text-secondary: grey;
  --accent: salmon;
}
html {
  scroll-behavior: smooth;
}
* {
  border: 0;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  /* width: 100vw; */
}

@media (min-width: 20em) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 30em) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 40em) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 60em) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 64em) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 80em) {
  /* hi-res laptops and desktops */
}
