/* ABOUT */
.About {
  height: auto;
  align-items: flex-start;
}
.About > * {
  margin-block: 2rem;
}
.About > a {
  font-size: 1.5rem;
  flex-direction: row;
  width: fit-content;
  text-align: center;
  align-items: center;
}
a > .icon {
  font-size: large;
  margin-left: 1rem;
}

@media (min-width: 20em) and (max-width: 40em) {
  /* MEDIA ABOUT */
  .About {
    word-spacing: 0.3rem;
    line-height: 2.2rem;
    width: 100%;
  }
}
